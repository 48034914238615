<template>
  <uploader />
</template>

<script>
export default {
  name: 'uploadFrontend',
  components: {
    uploader: () => import('@/components/uploader.vue'),
  },
};
</script>
